import dynamic from "next/dynamic";
import { ReactElement } from "react";
import { getServerSideProps } from "../screens/LandingScreen/getServerSideProps";

import type { LandingScreenProps } from "../screens/LandingScreen/LandingScreen";

const LandingScreen = dynamic(() => import("../screens/LandingScreen"));

const LandingPageLayout = dynamic(() => import("../layouts/LandingPageLayout"));

const LandingScreenWrapper = (props: LandingScreenProps) => {
  return <LandingScreen {...props} />;
};

LandingScreenWrapper.getLayout = function getLayout(page: ReactElement) {
  return <LandingPageLayout>{page}</LandingPageLayout>;
};

export { getServerSideProps };

export default LandingScreenWrapper;
